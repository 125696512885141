html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {margin:0;padding:0;border:0;outline:0;vertical-align:baseline;}
html { scroll-behavior: smooth;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block;}
*{outline:none !important; text-decoration:none!important;}
*, ::after, ::before {box-sizing: border-box;}
ol, ul {list-style:none;}
blockquote, q {quotes:none;}
blockquote:before, blockquote:after, q:before, q:after {content:'';content:none;}
ins {text-decoration:none;}
del {text-decoration:line-through;}
table {border-collapse:collapse;border-spacing:0; width:100%;}
a {text-decoration:none;color: #313131;transition:all 0.32s ease-out;outline:none!important;}
em {font-style:italic;}
strong {font-weight:bold;color:#000;}
img, object, iframe {max-width:100%;}
iframe{ display: block;}
h1, h2, h3, h4{line-height: 1.3;font-family: Cormorant Garamond;font-weight: 400;}
body{ font-size:14px; line-height:1.7; color: #313131;   overflow-x: hidden; font-family: 'Open Sans';    overflow-y: scroll!important;}
p{ margin-bottom:20px; color: #313131;font-family: 'Open Sans';}
html {-webkit-text-size-adjust:100%;}
small {font-size: medium;}
ol, ul {   padding-left: 0;  margin: 0;}
section{ overflow:hidden;}
a:hover {    color: #C4601D;}
button:disabled {opacity: .7;cursor: not-allowed;}
div.comm-error {
  width: 100%;
   margin-top: 0.25rem;
   font-size:12px;
   color: #dc3545;
   font-family: 'Open Sans'
}
.no-scroll{
  overflow: hidden !important;
}

.error {
  font-size: 14px;
  color: red !important;
  text-align: center;
}

@font-face {font-family: 'Open Sans'; src: url('/fonts/OpenSans-Regular.eot'); src: url('/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/OpenSans-Regular.woff2') format('woff2'), url('/fonts/OpenSans-Regular.woff') format('woff'), url('/fonts/OpenSans-Regular.ttf') format('truetype'), url('/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg'); font-weight: normal; font-style: normal; font-display: swap;}
@font-face {font-family: 'Open Sans'; src: url('/fonts/OpenSans-Bold.eot'); src: url('/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/OpenSans-Bold.woff2') format('woff2'), url('/fonts/OpenSans-Bold.woff') format('woff'), url('/fonts/OpenSans-Bold.ttf') format('truetype'), url('/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');font-weight: bold;font-style: normal;font-display: swap;}
@font-face {font-family: 'Open Sans'; src: url('/fonts/OpenSans-SemiBold.eot'); src: url('/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('/fonts/OpenSans-SemiBold.woff2') format('woff2'), url('/fonts/OpenSans-SemiBold.woff') format('woff'), url('/fonts/OpenSans-SemiBold.ttf') format('truetype'), url('/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg'); font-weight: 600;font-style: normal;font-display: swap;}
@font-face {font-family: 'Cormorant Garamond'; src: url('/fonts/CormorantGaramond-Medium.eot'); src: url('/fonts/CormorantGaramond-Medium.eot?#iefix') format('embedded-opentype'), url('/fonts/CormorantGaramond-Medium.woff2') format('woff2'), url('/fonts/CormorantGaramond-Medium.woff') format('woff'), url('/fonts/CormorantGaramond-Medium.ttf') format('truetype'), url('/fonts/CormorantGaramond-Medium.svg#CormorantGaramond-Medium') format('svg'); font-weight: 500;font-style: normal; font-display: swap;}
@font-face { font-family: 'Cormorant Garamond'; src: url('/fonts/CormorantGaramond-Bold.eot'); src: url('/fonts/CormorantGaramond-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/CormorantGaramond-Bold.woff2') format('woff2'), url('/fonts/CormorantGaramond-Bold.woff') format('woff'), url('/fonts/CormorantGaramond-Bold.ttf') format('truetype'), url('/fonts/CormorantGaramond-Bold.svg#CormorantGaramond-Bold') format('svg'); font-weight: bold;font-style: normal; font-display: swap;}
@font-face {font-family: 'Cormorant Garamond';src: url('/fonts/CormorantGaramond-Regular.eot'); src: url('/fonts/CormorantGaramond-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/CormorantGaramond-Regular.woff2') format('woff2'), url('/fonts/CormorantGaramond-Regular.woff') format('woff'), url('/fonts/CormorantGaramond-Regular.ttf') format('truetype'), url('/fonts/CormorantGaramond-Regular.svg#CormorantGaramond-Regular') format('svg');font-weight: normal; font-style: normal; font-display: swap;}
@font-face { font-family: 'Cormorant Garamond'; src: url('/fonts/CormorantGaramond-SemiBold.eot');  src: url('/fonts/CormorantGaramond-SemiBold.eot?#iefix') format('embedded-opentype'), url('/fonts/CormorantGaramond-SemiBold.woff2') format('woff2'),url('/fonts/CormorantGaramond-SemiBold.woff') format('woff'),url('/fonts/CormorantGaramond-SemiBold.ttf') format('truetype'),url('/fonts/CormorantGaramond-SemiBold.svg#CormorantGaramond-SemiBold') format('svg'); font-weight: 600; font-style: normal;  font-display: swap;}

video {max-width: 100%;}
.product-list{padding: 20px 0;}
.error-msg {color: rgb(97, 26, 21);background-color: rgb(253, 236, 234);padding: 10px;margin: 0 0 10px 0;}
.success-msg {color: rgb(30, 70, 32);background-color: rgb(237, 247, 237);padding: 10px;margin: 0 0 10px 0;}
.page_content {padding-bottom: 70px;    margin-top:76px;  display: flex; margin-top: 0;}
select{background: none;}
.col-6:nth-child(odd) { padding-right: 7px;}
.col-6:nth-child(even) {padding-left: 7px;}
.product-list .col-6{ margin-bottom: 20px;}
.btn_bag { display: block;width: 100%;}
.btn_bag a{ background: #a9671e; color: #fff;box-shadow: 0px 0px 16px rgba(82,53,40,0.16);border-radius: 5px;font-size: 12px;letter-spacing: 1.25px;text-transform: uppercase; display: block; padding: 13px; text-align: center;}
/* Accordian */
.accordion-body {padding: 20px 0}	
.miniCart_CarouselIcons .home_mobile_sec1 .item_home_main h4 { display: flex; flex-wrap: wrap;}
.accordion-item.accordion-body ul li {font-size: 14px;}
.accordion-body p{letter-spacing: 0.25px;font-size: 14px;margin-bottom: 0;}	
.accordion-body p:last-child{ margin: 0;}
.MuiAccordionSummary-content {margin: 0  !important;}
.MuiAccordionSummary-root {padding: 0  !important; }
.cetegory-product-sec1{ width: 100%; padding-top: 30px;}
.cetegory-product-sec1 span{ display: block;}
.cetegory-product-sec1 span img{ display: block; margin: 0 auto;}
.cetegory-product-colm { box-shadow: 0px 3px 6px rgb(49 49 49 / 16%);  background: #fff; border-radius: 5px; padding:20px 15px;  width: 100%;  display: inline-block;}
.cetegory-product-colm h3{font-size: 28px; line-height: 1.3;letter-spacing: 0.15px; padding-bottom: 10px; font-weight: 600;}
.cetegory-product-colm p{font-size: 14px;letter-spacing: 0.25px;}
.cetegory-product-colm p:last-child{ margin: 0;} 
/*** rohit global code ***/
.consultation-ftr-menu img {filter: grayscale(100%);opacity: 70%;}
.consultation-ftr-menu li a.active img {filter: unset;opacity: 100%;}
.product-list{padding: 20px 0;}
.category-product-menu ul { -ms-overflow-style: none;scrollbar-width: none;flexWrap: nowrap;transform: translateZ(0);list-style: none;overflow-y: auto;display: flex;    scroll-behavior: smooth;    white-space: nowrap;}
.category-product-menu ul::-webkit-scrollbar {display: none;}
.category-product-menu { width: 100%; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;}
.category-product-menu li { float: left; }
.category-product-menu li a:focus {background:#f9f4e8}
.category-product-menu li a {width: max-content; display: block;font-size: 13px; color: #C4601D; letter-spacing: 1.25px;text-transform: uppercase; font-weight: 600; padding: 11px 15px;}
.category-product-menu li a.active{ background:#f9f4e8; }
.consultation-ftr-menu {   border-radius: 5px; position: fixed; bottom: 0; background: #fff;  width: 100%; box-shadow: 0px 0px 16px rgb(82 53 40 / 16%); z-index: 9; padding: 8px 0 8px;}	
.consultation-ftr-menu li{ float: left; width: 20%;font-size: 12px; text-align: center;color: #313131;}	
.consultation-ftr-menu li a{ position: relative; width: 100%; display: block;color: rgb(49 49 49 / 70%);line-height: 1.1;    text-transform: capitalize;}	
.consultation-ftr-menu li a strong{background-color: #C4601D; font-weight: 400;position: absolute;top: -4px;
  height: 16px;display: block;text-align: center;border-radius:10px;color: #fff;
   font-size:9px;z-index: 1;line-height: 17px; min-width: 16px; padding:0 4px;    left:73%;
   transform: translate(-50%, 0px);}
.consultation-ftr-menu li span {display:table; margin: 0 auto 2px;}
.consultation-ftr-menu li a.active{ color: #C4601D; font-weight: 600;}
.error-msg {color: rgb(97, 26, 21);background-color: rgb(253, 236, 234);padding: 10px;margin: 0 0 10px 0;}
.success-msg {color: rgb(30, 70, 32);background-color: rgb(237, 247, 237);padding: 10px;margin: 0 0 10px 0;}
.page_content {padding-bottom: 30px;}
select{background: none;border: none;}
.col-6:nth-child(odd) { padding-right: 7px;}
.col-6:nth-child(even) {padding-left: 7px;}
.react-multi-carousel-list .react-multiple-carousel__arrow{   top: 38%;  opacity: .7;background-color: inherit !important; border-radius: 0;    min-width: inherit;  min-height: inherit; padding: 0;  z-index: 9;}
.react-multi-carousel-list .react-multiple-carousel__arrow:before{font-size:30px; color: #c4601d;    font-weight: bolder;   }
.react-multi-carousel-list .react-multiple-carousel__arrow--left{ left:-25px; }
.react-multi-carousel-list .react-multiple-carousel__arrow--right{     right:-17px; }
.clp_filter_main.miniCart_CarouselIcons .react-multi-carousel-list .react-multiple-carousel__arrow--left{left: -15px !important;}
.clp_filter_main.miniCart_CarouselIcons .react-multi-carousel-list .react-multiple-carousel__arrow--right{right: -10px !important;}
.gallery .carousel .thumbs-wrapper { position: absolute;     left: -80px;    margin: 0;}
.gallery .carousel .thumbs-wrapper .thumbs li.thumb {width: 60px !important; background: rgb(0 0 0 / 6%);height: 60px;    border: 1px solid rgb(196 96 29 / 0%); margin-bottom: 15px; display: block;}
.gallery .carousel .thumbs-wrapper .thumbs li.thumb.selected{    border: 1px solid #c4601d;}
.gallery .carousel .slider-wrapper{    padding-bottom:40px;}
.gallery .carousel .control-dots li.dot {width: 10px; height: 10px;  background: initial;  box-shadow: inherit;
 border: 1px solid #c4601d;  opacity: 1;  margin: 0 5px;}
.gallery .carousel .control-dots li.dot.selected { background: #c4601d;}
.gallery .carousel .slide img{ width: auto;height: auto;    image-rendering: -webkit-optimize-contrast;}
.btn_bag { display: block;width: 100%;}
.btn_bag a{ background: #a9671e; color: #fff;box-shadow: 0px 0px 16px rgba(82,53,40,0.16);border-radius: 5px;font-size: 12px;letter-spacing: 1.25px;text-transform: uppercase; display: block; padding: 13px; text-align: center;}
.MuiAccordionSummary-content,.MuiIconButton-edgeEnd{  margin:0px !important; position: unset !important; z-index: 1 !important}
.MuiPaper-elevation1 { box-shadow: none !important; border-bottom:0 !important;}
.MuiAccordionSummary-content a{  border-bottom: none !important;}
.MuiAccordionDetails-root { display: unset !important;  padding: 0px !important; }
.MuiAccordionSummary-root.Mui-expanded { min-height: 48px !important; border-bottom: 1px solid #f0ddd1;}
.page_content{display: inherit; }
.category-product-menu{ min-height: 44px;   box-shadow: 0px 1px 2px rgb(82 53 40 / 16%);}
.wp-block-image {    display: inline-block;	margin-bottom: 20px;border-radius: 5px; overflow: hidden;box-shadow: 0px 3px 6px rgb(49 49 49 / 16%);}
.wp-block-image img {	display: block;height: auto;}
.faq_book .MuiCollapse-wrapperInner { padding-top: 20px;}
.noun_border_border { text-align: center;}
.noun_border_padding { padding-bottom:50px;}
.MuiSnackbarContent-root {background-color: #fffdee !important;color:inherit!important}
body .react-multi-carousel-list{ position: static;}
.pdp_faq .MuiIconButton-root:before{content: "";  position: absolute; background-image: url(/images/drop-down-arrow.inline.svg);width: 28px; height: 28px; right: 0; -webkit-transition: all 0.32s ease-out; -moz-transition: all 0.32s ease-out;-o-transition: all 0.32s ease-out;  transition: all 0.32s ease-out; top: -2px; transform: rotate( 180deg );}
.pdp_faq .MuiAccordionSummary-expandIcon.Mui-expanded:before{ transform: rotate( 0deg );}
.doshas_pages {  display: inline-block; width: 100%;}
.doshas_pages span {display: block; width: 100%;}
.doshas_pages span img{ width: 100%; display: block;}
.doshas_pages h3 { font-size: 28px; font-weight: 500;   padding-top: 20px; line-height: 1.2; padding-bottom:10px;  letter-spacing: 0.25px;}
.ingredient_detail h2{font-size: 22px; margin-top: 2rem;text-align: left;  font-weight: 600;}
.ingredient_detail{ padding-bottom: 10px;}
 .hamburger_menu .MuiIconButton-root:before{content: ""; background-image: url(/images/arrow-icon.inline.svg);  height: 14px; width: 8px;position: absolute;right:15px;top: 5px;transition: all 0.32s ease-out;} 
 .MuiAccordionSummary-expandIcon.Mui-expanded{transform: inherit !important;}
 .hamburger_menu .MuiAccordionSummary-expandIcon.Mui-expanded:before {transform: rotate( 90deg);}
 .infinite-scroll-component .row{ margin: 0 -7px;}
 .infinite-scroll-component .col-6{ padding-left: 7px; padding-right: 7px;}
 .MuiAccordion-root:before{ display: none;}
 .hamburger_menu .MuiPaper-elevation1{ border-bottom: 1px solid #f0ddd1 !important;     border-radius: 0 !important;}
 .pdp_faq .MuiAccordion-root.Mui-expanded { margin: 0 !important;}
 .hamburger_menu1 ul li ul li:last-child a{    border-bottom: 0;}
 .table tr th { background: #fffdee;color: #c4601d;  padding: 8px 12px; font-size: 14px; font-weight: 600;border-left: 1px solid #f9f4e8;}
.table tr td {  border-left: 1px solid #f9f4e8;  padding:6px 12px;  font-size: 13px; border-bottom: 1px solid #f9f4e8;}
.table tr td:first-child{ border-left: 0;}
.table tr th:first-child{ border-left: 0;}
.pdp_sec88 .row{    align-items: center;}
.MuiSnackbar-anchorOriginBottomCenter {bottom: 65px;}
.MuiSnackbar-anchorOriginBottomCenter a{color: #c4601d;padding-left: 15px;font-weight: 600;}
/*frsh chat**/
#custom_fc_button {  width: 60px; position: fixed;right: 15px; bottom: 20px;z-index: 9;border-radius: 34px 8px 34px 34px;  box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);height: 60px;background: #fffdee; text-align: center;  padding-top: 13px;} 
#custom_fc_button a{ display: block; cursor: pointer;}
.CookieConsent { bottom: 60px !important; overflow: auto;    background: rgb(255 253 238) !important; color: #313131 !important;border-radius: 5px; box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);padding:25px;    max-width: 88%;transform: translate(-50%, 0); left: 50% !important;    display: block !important;}
.CookieConsent button {margin-top: 0 !important; background: #c4601d !important; color: #fff !important;border-radius: 5px !important; padding: 13px 20px !important; margin: 0 !important; width: 100%; text-transform: uppercase;font-weight: 600;letter-spacing: 1.25px; font-size: 12px;     margin-top:20px !important;}
.CookieConsent div{ margin: 0 !important;}
.CookieConsent h4 {  font-weight: 600; font-size: 22px; padding-bottom: 6px; color: #313131;}
.CookieConsent a{ color: #c4601d;}
.CookieConsent span{    vertical-align: text-top; margin-left: 5px;}
.MuiSnackbar-root.MuiSnackbar-anchorOriginTopCenter{ z-index: 99999;}
.plp_sec1 .infinite-scroll-component { overflow: inherit !important;}
form{ width: 100%;}
.doshas_pages .container-fluid{ padding: 0;}
.p-col-0{ padding: 0;}
.category-product-menu li a{ position: relative;}
.category-product-menu .new{    font-weight: 600;  letter-spacing: 1px;  background: #c4601d;color: #fff;padding: 2px 4px;border-radius: 3px;font-size: 7px; line-height: 1; position: absolute; bottom: 29px;font-family: 'Open Sans';text-transform: uppercase;white-space: nowrap;    left: 50%; transform: translate(-50%, 0);}
.home_mobile_sec1 .item_home{ border-radius: 5px; width: 100%; margin-bottom: 12px;margin-top: 10px; padding:0px 0px 20px;box-shadow: 0px 0px 6px rgba(49,49,49,0.16);position: relative;}
body .home_mobile_sec1 h4{padding-bottom: 0;}
.home_mobile_sec1 .item_home_main{ padding: 0 20px;}
.home_mobile_sec1 .item_home span{ background-color: #fff;    padding-bottom: 0; }
.hamburger_menu ul li li.level_4 a { background: #FFF2D9; font-weight: 600;}
.pdp_faq .MuiIconButton-root span{ display: none;}
.category_listing_sec2_carousel .react-multi-carousel-list .react-multiple-carousel__arrow{ top:48%;}
.category_listing_sec2_carousel .react-multi-carousel-list .react-multiple-carousel__arrow::before{ font-size: 32px;}
.category_listing_sec2_carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left {left: -32px;}
.cart_item_order .shipping_items_cart { margin: 0;}
.gallery .carousel .slider-wrapper li iframe { margin: 0; width: 100%;}
/* .home_mobile_sec1 .item_home .video_icon a{background-image: url(/images/video-icon-white.svg);}
.home_mobile_sec1 .item_home .video_icon a img{ display: none;}
.home_mobile_sec1 .item_home .video_icon a:hover{background-image: url(/images/video-icon-full-white.svg);} */
.or_colm {   margin:2px auto 15px;  display: table;  position: relative;}
.or_colm:before {  content: "";  background: #c7c7c7; width: 160px;  height: 1px; position: absolute;   left: 50%;  transform: translate(-50%, 0); top: 12px;}
.or_colm span { background: #fff; position: relative;  padding: 0 20px;}
.invalid_otp{    width: 100%; margin-top: 0.25rem; font-size: .875em;color: #dc3545;}
.swipe .carousel .thumbs-wrapper{ display: none;}
.swipe .carousel .control-dots li{    background-color: #fff; width: 9px;  height: 9px;  margin: 0 4px;border: 1px solid #c4601d;cursor: pointer;    box-shadow: inherit;opacity: 1;}
.swipe .carousel .control-dots li.selected{background-color: #c4601d;}
.gifting-banner .react-multiple-carousel__arrow--left::before{    content: ""; margin: 0;display: block;text-align: center; z-index: 2;position: relative;border: solid #c4601d;border-width: 0 3px 3px 0;display: inline-block; padding: 4px; transform: rotate(135deg); left: 1px;}	
.gifting-banner .react-multiple-carousel__arrow--left{ position: absolute;opacity: 1;top: 50%; transform: translateY(-50%); transition: all .32s ease-out; color: #c4601d;background: #f9f4e8 !important;  width: 42px; height: 42px; border-radius: 50%;padding: 0; font-size: 0; left: 15px;}
.gifting-banner .react-multiple-carousel__arrow--right::before{    content: ""; margin: 0;display: block;text-align: center; z-index: 2;position: relative;border: solid #c4601d;border-width: 0 3px 3px 0;display: inline-block; padding: 4px;    transform: rotate(-45deg); right: 1px;}	
 .gifting-banner .react-multiple-carousel__arrow--right{ position: absolute;opacity: 1;top: 50%; transform: translateY(-50%); transition: all .32s ease-out; color: #c4601d;background: #f9f4e8 !important;  width: 42px; height: 42px; border-radius: 50%;padding: 0; font-size: 0; right: 15px;}
 .cart_page .crossSellCarouselItem h4 , .cart_page .list-item_cutoffprice__3w1X5 {font-size: 14px;}
.cart_page .home_mobile_sec1 .item_home h3{padding:0; min-height: 55px;} 
.cart_page .crossSellCarouselItem span{ height: 130px; display: flex;}
.cart_page .crossSellCarousel h2 {font-size: 23px;}
.cart_page .col-9.Home_col-9__1VSyb{ width: 50%; }
.cart_page .crossSellCarousel {margin-top: 30px;}

.clp_filter_main.miniCart_CarouselIcons .home_mobile_sec1 .item_home h3 , .cart_page .home_mobile_sec1 .item_home h3{ 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; line-height: 1.7 !important; padding-bottom: 0px !important; }
  .cart_page .home_mobile_sec1 .item_home h3{   min-height: 70px;   }

 @media (min-width: 992px){
  .amarrupaLowerBannerBtn0{
    position: absolute !important;
    right: 22%;
    left: auto;
    bottom: 22%;
    max-width: 10% !important;
  }

  .amarrupaBtn0{
    position: absolute;
    bottom: 0;
    left: 40%;
    margin: 0 !important;
  }
  
  .amarrupaBtn1{
    position: absolute;
    bottom: 0;
    left: 36%;
    margin: 0 !important;
  }
  
  .amarrupaBtn2{
    position: absolute;
    bottom: 0;
    left: 45%;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  
  .amarrupaBtn3{
    position: absolute;
    bottom: 0;
    left: 36%;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }

.miniCart_CarouselIcons .home_mobile_sec1 .row{margin: 0 0 !important;}
.ingredient_detail {padding-bottom: 30px;padding-top: 30px;}
.ingredient_detail h2{    text-transform: capitalize;letter-spacing: 0.25px;padding-bottom:8px;font-size: 33px;font-weight: 400;    line-height: 1;}
.gallery .carousel .thumbs-wrapper .thumbs { transform: inherit !important; transition-duration: inherit !important;}
.gallery .carousel { position: static;}
.gallery .carousel.carousel-slider {position: relative;}
.gallery .carousel.carousel-slider .control-arrow:hover {background: rgb(0 0 0 / 0%);}
.gallery .carousel.carousel-slider .control-arrow {opacity: .7;    bottom: 38px;    padding: 0;}
.gallery .carousel .control-arrow:before, .gallery  .carousel.carousel-slider .control-arrow:before{ margin: 0;border: 0;}
.gallery .carousel .control-next.control-arrow:before {font-size: 28px; color: #c4601d; font-weight: bolder;content: "\e825"; font-family: revicons;border: 0;}
.gallery .carousel .control-prev.control-arrow:before {font-size: 28px; color: #c4601d; font-weight: bolder;content: "\e824"; font-family: revicons;border: 0;}
.gallery .carousel.carousel-slider .control-disabled.control-arrow{    display: block; opacity: .4 !important;}
.gallery .carousel.carousel-slider .control-arrow:focus, .gallery .carousel.carousel-slider .control-arrow:hover{ opacity: 1;}
.kind-to-people {padding-top: 60px; }
.gallery { padding-bottom: 0 !important;}
.CookieConsent{ transform: translate(-50%, -50%); top: 58%;bottom: auto !important;padding: 30px;max-width: 600px;}
.CookieConsent button{    padding: 13px 40px !important;    width: auto;}
body .home_mobile_sec1 .item_home svg{ right: 16px;    bottom: 20px;}
body .home_mobile_sec1 h2{ padding-bottom: 5px;}
.doshas_pages .container-fluid{max-width: 1050px; padding: 0 12px; }
.container { max-width: 1050px;}
.page_content{    display: inline-block;width: 100%;margin-top: 160px;padding-bottom: 60px;}
.infinite-scroll-component .col-6{    width: 33.3333333333%; padding-left: 12px; padding-right: 12px;}
.infinite-scroll-component .row{ margin-left: -12px; margin-right: -12px;}
.doshas_pages{ padding-top: 0px;}
.breadcrumbs_sec{    width: 100%; display: flex; padding-top: 20px;}
.breadcrumbs_sec ol li{ float: left;}
.breadcrumbs_sec ol li a{    font-size: 10px; display: block;  color: #C4601D;letter-spacing: 1.5px;
text-transform: uppercase; padding-right: 10px;margin-right: 10px;position: relative;}
.breadcrumbs_sec ol li a:before {content: ">";  position: absolute; right:-4px; top:0;}
.breadcrumbs_sec ol li:last-child a:before{content: ">>"; right:-10px;}
.doshas_pages .row{ padding-top: 20px;}
.p-col-0{ padding: 0 12px;}
.blog_detail_sec_colm7 .pdp-sec19 .row{    flex-wrap: wrap;    white-space: inherit;}
.blog_detail_sec_colm7 .pdp-sec19 .col-md-4{ margin-bottom: 40px !important;}
.blog_detail_sec_colm9:before{content: ""; background-image: url(/images/motif1.inline.svg); width:130px; height:39px;background-size: cover; position: absolute; top:-39px;    background-repeat: no-repeat; transform: translate(-50%, 0);  left: 50%;}
.blog_detail_sec_colm9:after{content: ""; background-image: url(/images/motif1.inline.svg); width:130px; height:39px;background-size: cover; position: absolute; bottom:-39px;    background-repeat: no-repeat; transform: translate(-50%, 0) rotate(180deg);  left: 50%;}
.blog_detail_sec_colm9 a{ background-color: #C4601D;letter-spacing: 1.25px; color: #fff;text-transform: uppercase;font-size: 12px; width: 100%; text-align: center;
padding: 12px;border-radius: 5px;box-shadow: 0px 0px 16px rgba(82,53,40,0.16); display: block; margin-top: 20px;}             
.blog_detail_sec_colm9{ margin-top: 35px; margin-bottom:50px; position: relative; background-color: #f0ddd1; border-radius: 5px; display: inline-block; width: 100%; padding: 25px;}
.MuiDrawer-root ::-webkit-scrollbar { width: 8px;}
/* Track */
.MuiDrawer-root ::-webkit-scrollbar-track { background: #f1f1f1; } 
  /* Handle */
.MuiDrawer-root ::-webkit-scrollbar-thumb { background: #f0ddd1;border-radius: 8px; }
  /* Handle on hover */
 .MuiDrawer-root ::-webkit-scrollbar-thumb:hover { background: #f3c7ab; border-radius: 8px;}
 .doshas_pages h3{     font-size: 40px;padding-top: 0; padding-bottom: 15px; font-weight: 400;letter-spacing: 0.25px;}
 .doshas_pages p{letter-spacing: 0.5px;font-size: 13px; line-height: 30px;}
 .ingredient_detail_btn{ display: none;}
 .doshas_pages .cart-checkout-btn a { background-color: #C4601D;max-width: 181px;text-align: center;  text-transform: uppercase;letter-spacing: 1.25px; width: 100%; cursor: pointer; color: #fff !important; display: block; padding: 13px 0; border-radius: 5px; font-size: 12px;  font-weight: 600;}
  .home_mobile_sec1 .item_home .video_icon { left: 20px;top: 20px;}
.gallery .carousel .thumbs-wrapper button.control-arrow { display: none;}
  .gallery .carousel .thumbs-wrapper ul.thumbs li iframe {pointer-events: none;}
  .gallery .carousel .thumbs-wrapper{       overflow-y: auto; max-height: 455px;  position: absolute; top: 0;}
  .gallery .carousel .thumbs-wrapper::-webkit-scrollbar { width: 6px; height: 6px;  }
  .gallery .carousel .thumbs-wrapper::-webkit-scrollbar-track { background: #fff; } 
.gallery .carousel .thumbs-wrapper::-webkit-scrollbar-thumb { background: #f0ddd1;border-radius: 8px; }
.gallery .carousel .thumbs-wrapper::-webkit-scrollbar-thumb:hover { background: #f3c7ab; border-radius: 8px;}
.bannerdots_button .MuiMobileStepper-dots { background: initial; position: absolute; bottom: -25px;}
.bannerdots_button .MuiMobileStepper-root{  justify-content: center;    background: initial;   padding: 0;}.bannerdots_button .MuiMobileStepper-dot{ background-color: #fff; width: 9px; height: 9px; margin: 0 4px; border: 1px solid #c4601d; cursor: pointer;}
.bannerdots_button .MuiMobileStepper-dotActive{background-color: #c4601d;}
.mincart_open .cart_listing_remove{ position: relative; z-index: 991;}
/* .complimentary_box .cart_listing_sec2_bottom{ padding: 0; border: 0;} */
.swipe .carousel button.control-arrow{position: absolute; opacity: 1;top: 50%;transform: translateY(-50%);transition: all .32s ease-out; color: #c4601d; background: #f9f4e8; width: 42px;height: 42px; border-radius: 50%; padding: 0;    font-size: 0; left: 15px;   }
 .swipe .carousel button.control-prev:before{content: ""; margin: 0;    display: block;  border: 0;text-align: center;z-index: 2; position: relative;   border: solid #c4601d;border-width: 0 3px 3px 0; display: inline-block; padding: 4px; transform: rotate(135deg);  left: 1px; }
.swipe .carousel button.control-next{ left: auto; right: 15px;}
.swipe .carousel button.control-next:before{content: ""; margin: 0;    display: block;  border: 0;text-align: center;z-index: 2; position: relative;   border: solid #c4601d;border-width: 0 3px 3px 0;display: inline-block; padding:4px;   transform: rotate(-45deg);     right: 1px;}
.swipe .carousel.carousel-slider .control-arrow:hover{background: #f9f4e8; }
.swipe .carousel .control-disabled.control-arrow{ display: block; opacity: .9;}
.blog_detail_sec_colm7 .pdp-sec19 .col-md-4 .MuiSnackbar-anchorOriginBottomCenter{ position: absolute; bottom: -48px;    transform: inherit;left: 15px;}
.gifting-banner ul.react-multi-carousel-dot-list{ display: none;}
.cart_page .crossSellCarouselItem h4 , .cart_page .list-item_cutoffprice__3w1X5 , .clp_filter_main.miniCart_CarouselIcons .crossSellCarouselItem h4 , .clp_filter_main.miniCart_CarouselIcons .list-item_cutoffprice__3w1X5{font-size: 14px;}
.cart_page .crossSellCarouselItem h3 , .clp_filter_main.miniCart_CarouselIcons .crossSellCarouselItem h3{padding:0; min-height: 50px !important;} 
.cart_page .crossSellCarouselItem span , .clp_filter_main.miniCart_CarouselIcons .crossSellCarouselItem span{ height: 130px;}
.cart_page .crossSellCarousel h2 , .clp_filter_main.miniCart_CarouselIcons .crossSellCarousel h2{font-size: 23px; width: 100%; word-wrap: break-word;}
.clp_filter_main.miniCart_CarouselIcons .crossSellCarousel {margin-top: 10px;}
.clp_filter_main.miniCart_CarouselIcons .crossSellCarouselItem{margin: 0;}
}
 @media (max-width: 767px){
  .amarrupaLowerBannerBtn0{
    position: absolute !important;
    right: 12%;
    left: auto;
    bottom: 2%;
    max-width: 32% !important;
  }
  .amarrupaLowerBannerBtn0 button {
    font-size: 10px !important;
  }
.ingredient_detail h2{ line-height: 1;}
 .ingredient_detail{ padding-top: 10px;}
.ingredient_detail .row{    transform: translateZ(0);scroll-behavior: smooth;    flex-wrap: nowrap;overflow: auto;    margin: 0 -7px; overflow-scrolling: touch;}
.ingredient_detail .col-md-4{ padding: 0 7px;}
.ingredient_detail ::-webkit-scrollbar{ width: 0; height: 0;}
.swipe .carousel button.control-arrow{ display: none;}
.stock_bag_main{display: flex;flex-direction: column; justify-content: flex-end; height: 100%;}
.stock_bag_bax{    display: flex; flex-direction: column; justify-content: flex-end;}
.plp_sec1 .col-8{ width: 50%; padding-left: 7px; padding-right: 7px; margin-bottom: 20px;}
.plp_sec1 .col-8 > div{ margin-bottom: 0;display: flex; flex-direction: column;}
.plp_sec1 .col-8 > div h3{ min-height: 60px;}
.pdp-sec19 .col-8{ padding: 0 7px;}
.doshas_pages .container-fluid { padding: 0 12px;}
.doshas_pages .pe-0{ padding-left: 0 !important;}
.cart-checkout-btn { z-index:99;  display: inline-block; width: 100%; left:0;    position: fixed; bottom: 5px;}		
.cart-checkout-btn a {  background-color:#C4601D; text-align: center;text-transform: uppercase; letter-spacing: 1.25px;width: 100%; color: #fff !important;  display: block; padding: 13px 0;border-radius: 5px; font-size: 12px; font-weight: 600;}				
.cart-checkout-btn a:hover{ color: #fff;}
.home_mobile_sec1 .item_home{padding:0px 0px 20px;margin-top: 0;margin-bottom:0px; border-bottom-right-radius: 0;border-bottom-left-radius: 0;margin-top: 2px;}
.home_mobile_sec1 .item_home_main { padding: 0 15px;}
.home_mobile_sec1 .item_home svg{    right:12px;  top:12px; }
.px-col-0{    padding-right: 0!important;padding-left: 0!important;}
.gallery .carousel .thumbs-wrapper{ display: none;}
.home_mobile_sec1 .row { padding-top: 5px;}
.kind-to-people p {letter-spacing: 0.5px; font-size: 13px; margin-bottom: 10px;} 
.home_mobile_sec1 .item_home h3{    min-height: 84px;}
.miniCart_CarouselIcons .home_mobile_sec1 .item_home h3{min-height: 50px;}
.miniCart_CarouselIcons .home_mobile_sec1{margin-top: 10px;}
.home_mobile_sec1 .item_home .video_icon { left:15px;top:15px;}
.gallery .carousel .slider-wrapper {  padding-bottom: 40px;}
.top_bar a{ cursor: pointer; align-items: center;  background: #f9f4e8;width: 38px;height: 38px;  position: fixed;bottom:90px; left: 12px;border-radius: 50%; display: flex; justify-content: center; z-index: 92;}
.top_bar a img{    transform: rotate(-90deg);}
.category-product-menu.sticky_category { position: fixed; min-height: 35px; top: 48px; z-index: 9;background: #fff;    animation-play-state: running;animation-timing-function: ease; transform: translate(0,0); animation-delay: .3s; animation-direction: normal;animation-duration: 1s; animation-iteration-count: 1; animation-fill-mode: both;animation-name: fadeInDown;}
.category-product-menu.sticky_category li a{     font-size: 11px;    padding: 8px 11px;  }
.category-product-menu.sticky_category li .new{padding: 1.5px 4px; bottom: 24px; font-size: 6px;} 
#custom_fc_button a img {width: 25px;}
#custom_fc_button{    right: 12px;bottom: 90px; width: 40px; height: 40px;padding-top: 9px;}
.custom_fc_button_mobile , .top_bar_mobile{ bottom: 20px !important;}

.sticky_category + .page_content{ padding-top: 82px;}
#nprogress .bar{    height: 3px !important;    background: #f0ddd1 !important;}
#nprogress .peg{    box-shadow: inherit !important;}
.blog-related .row {  flex-wrap: nowrap; margin: 0 -7px;    overflow: auto;}
.blog-related { padding-top: 10px;}
.page-ayurveda { padding-top: 10px;}
.page-ayurveda h3{font-size: 22px;font-weight: 600;padding-bottom: 5px;}
.sticky_category + .sticky_category_margin{ padding-top: 44px;}
:root { --fw-player-margin: 8px 8px 50px 8px;}
 }
 @media screen and (min-device-width: 411px) and (max-device-width: 767px) { .category-product-menu li a{padding: 11px 20px;}}
 @-webkit-keyframes fadeInDown { from { opacity:0; -webkit-transform: translatey(-10px);-moz-transform: translatey(-10px);-o-transform: translatey(-10px); transform: translatey(-10px);}   to {opacity:1; -webkit-transform: translatey(0); -moz-transform: translatey(0); -o-transform: translatey(0);transform: translatey(0); } }
 @-moz-keyframes fadeInDown { from {  opacity:0; -webkit-transform: translatey(-10px); -moz-transform: translatey(-10px);-o-transform: translatey(-10px);transform: translatey(-10px);} to {  opacity:1; -webkit-transform: translatey(0);  -moz-transform: translatey(0);  -o-transform: translatey(0);transform: translatey(0);} }
 @keyframes fadeInDown { from { opacity:0; -webkit-transform: translatey(-10px); -moz-transform: translatey(-10px);-o-transform: translatey(-10px); transform: translatey(-10px); } to {  opacity:1; -webkit-transform: translatey(0); -moz-transform: translatey(0);-o-transform: translatey(0); transform: translatey(0);}}
figure.wp-block-image a {position: relative;  display: block;}
figure.wp-block-image a:before { content: "Shop Now"; position: absolute; bottom: 0; text-align: center; width: 100%; background: rgb(196 96 29 / 70%);color: #fff; font-size: 12px;text-transform: uppercase;font-weight: 600; letter-spacing: 0.25px;padding: 10px;}

@media (max-width: 374px){ .home_mobile_sec1 .item_home h3 { min-height: 109px;}      }
.ril__outer { background-color: rgba(255, 255, 255, 1) !important;}
.carousel img { pointer-events: unset !important; }
.gallery .carousel.carousel-slider .control-arrow { display: none !important;}
.zoom-icon{ z-index: 1; position: absolute;  width: 28px;  cursor: pointer; top: 111px; right: 20px;}
.ril__toolbar ul li button {filter: invert(1);}
div .ril__toolbar { background-color: unset;}
@media (max-width: 991px){.zoom-icon { position: absolute;width: 26px; cursor: pointer; right: 15px;top: unset;z-index: 28;    top: 67%;transform: translate(0,-50%);}      }
@media (max-width: 767px){.gallery .carousel .slider-wrapper li iframe{ height: 400px;}}
/* stylelint-disable selector-list-comma-newline-after */
:root {--fw-storyblock-background: rgba(255, 255, 255, 0);}
div .MuiFormHelperText-root.Mui-error{     color: #ff0000;}
.nprogress-busy body .mega-menu{ opacity:0; visibility: hidden; }
.sticky_fc_custom { position: fixed;top: 0;}

.checkoutlogin .MuiDialogContent-root { padding: 25px !important;}
.checkoutlogin button.guest-continue-btn{ position: absolute;background: #f9f4e8; right: -19px;    z-index: 1;
top: -19px; width: 38px; height: 38px; border-radius: 50%;text-align: center;display: flex; justify-content: center; align-items: center;}
.checkoutlogin button.MuiButtonBase-root.MuiIconButton-root svg {   color: #c4601d;}
.checkoutlogin .MuiPaper-root {margin: 0 !important;  overflow: inherit;}
.checkoutlogin .guest-continue{ font-size: 12px; font-weight: 600; font-family: 'Open Sans'; color: #c4601d; background-color: #fff; border: 1px solid #c4601d; display: block; width: 100%;
text-align: center;padding: 10px;margin-top: 20px;}
.checkoutlogin p{ margin: 0;}
.complimentary_box .complimentary_colm {float: left;top: 0;padding-right:60px; text-align: center;}
.complimentary_box .listing_colm2 {width: auto;float: right;}
.complimentary_box .listing_colm2 abbr {padding-right: 22px;}
.complimentary_box div .number input {background: none;margin: 0 auto; display: block; line-height: 1.8;}
.category-banner .react-multiple-carousel__arrow--left::before{    content: ""; margin: 0;display: block;text-align: center; z-index: 2;position: relative;border: solid #c4601d;border-width: 0 3px 3px 0;display: inline-block; padding: 4px; transform: rotate(135deg); left: 1px;}	
.category-banner .react-multiple-carousel__arrow--left{ position: absolute;opacity: 1;top: 50%; transform: translateY(-50%); transition: all .32s ease-out; color: #c4601d;background: #f9f4e8 !important;  width: 42px; height: 42px; border-radius: 50%;padding: 0; font-size: 0; left: 15px;}
.category-banner .react-multiple-carousel__arrow--right::before{    content: ""; margin: 0;display: block;text-align: center; z-index: 2;position: relative;border: solid #c4601d;border-width: 0 3px 3px 0;display: inline-block; padding: 4px;    transform: rotate(-45deg); right: 1px;}	
.category-banner .react-multiple-carousel__arrow--right{ position: absolute;opacity: 1;top: 50%; transform: translateY(-50%); transition: all .32s ease-out; color: #c4601d;background: #f9f4e8 !important;  width: 42px; height: 42px; border-radius: 50%;padding: 0; font-size: 0; right: 15px;}
.category-banner { position: relative; width: 100%;}
.category-banner ul li div div{ display: block !important;}
.sample-banner .react-multi-carousel-dot-list  li{ padding: 0;}
.sample-banner .react-multi-carousel-dot-list{    bottom: -30px;}
.sample-banner .react-multi-carousel-dot-list  li button{ border: 1px solid #C4601D; background-color: #fff;}
.sample-banner .react-multi-carousel-dot-list li.react-multi-carousel-dot.react-multi-carousel-dot--active button{ background:#C4601D;}
.sample-banner{position: relative;}
.sample-banner .react-multiple-carousel__arrow--left{background: none !important; left: 0;}
.sample-banner .react-multiple-carousel__arrow--left::before{ padding: 7px;}
.sample-banner .react-multiple-carousel__arrow--right{background: none !important; right: 0;}
.sample-banner .react-multiple-carousel__arrow--right::before{padding: 7px;}
.sample-banner .col-md-4{ width: 100% !important;pointer-events: none;    height: 100%;}
.sample-banner .video_icon { display: none !important;}
.sample-banner .stock_bag_bax > div, .sample-banner .btnlistwish, .sample-banner .category_star{ display: none !important;}
.sample-banner .stock_bag_bax h4{ padding-bottom: 10px;}
.sample-banner .stock_bag_main{display: flex !important;flex-direction: column; justify-content: flex-end; height: 100%;}
.sample-banner .stock_bag_bax{    display: flex !important; flex-direction: column; justify-content: flex-end;}
.sample-banner .col-md-4 > div { margin-bottom: 0;display: flex !important;flex-direction: column; height: 100%;}
.christmas_mobile{margin-top: 20px; }
.complimentary_payment_sample .christmas_mobile{margin-top: 20px;}
.product_navratri .react-multi-carousel-dot-list {bottom: -10px;}
.product_navratri .react-multiple-carousel__arrow--left{left: 20px; opacity: 1;}
.product_navratri .react-multiple-carousel__arrow--right{ right: 20px; opacity: 1;}
@media (max-width: 767px){
  .product_navratri .react-multi-carousel-dot button{ width: 9px; height: 9px;}
/* .complimentary_box .cart_listing_sec2_top { min-height: 118px;} */
.complimentary_box .listing_colm2 {padding-top: 0px; margin-top: -12px;}
.complimentary_box .listing_colm2 abbr {padding-right: 0;}
.complimentary_box div .number { width: auto; margin-left: 0;}
.complimentary_box .listing_colm2{ float: left;}
div .complimentary_box div .number input{ line-height: 1;}
.MuiDrawer-paperAnchorRight{ width: 100%;}
.plp_sec1 .row{ margin: 0 -7px;}
.complimentary_box .complimentary_colm{ padding-right: 0;}
.category-banner .react-multi-carousel-dot-list{ bottom: 20px;}
.category-banner .react-multi-carousel-dot button{    background-color: #fff;width: 11px; height: 11px; margin: 0 4px;
  border: 1px solid #c4601d;cursor: pointer;}
  .category-banner .react-multi-carousel-dot--active button{ background-color: #c4601d;}
  .complimentary_box .complimentary_colm { padding-right: 0; position: absolute; bottom: 40px; right: 15px;height: auto; top: auto;}
  .sample-banner .react-multi-carousel-dot-list{ bottom: 5px;}
  .sample-banner .stock_bag_bax h4 { padding-bottom: 16px;}
  .christmas_mobile{margin-top: 30px; }
  .complimentary_mobile_sample .christmas_mobile{margin-top: 15px;}
  .complimentary_payment_sample .christmas_mobile{margin-top:30px;}
  .product_navratri .react-multi-carousel-dot-list {bottom: -15px;}
  .sample-banner .col-md-4{ height: auto;}
}
.consultation-ftr-menu ul{ display: flex;justify-content: center;}
.consultation-ftr-menu li{ width: 100%;}

