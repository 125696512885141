header.header {
  padding: 5px 0 0 0;
  background-color: #fffdee;
  position:relative;
    width: 100%;
    top: 0;
    z-index:99; padding-top: 8px;
}
.backBtn a {
  background-image: url("/images/back-arrow.inline.svg");
  width: 28px;
  height: 28px;
  display: inline-block;
}
.logoMain a {
  margin-top: 2px;
  margin: 0 auto;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.logoMain {
  padding: 3px 0;
  text-align: center;
}
.rightMenu ul li.searchBox a {
  display: block;
}
.rightMenu ul li.bagCart a {
  display: block;
}
.rightMenu ul li {
  float: left;
  list-style-type: none;
}
.rightMenu ul {
  float: right;
  margin: 0;
  padding: 0;
}
.backBtn {
  padding-top: 9px;
}

.rightMenu ul li {
  float: left;    position: relative;
  margin-left: 15px;
}

.hamburger_menu1:before {
  content: "";
  background-image: url(/images/motifs-icon-bg.inline.svg);
  position: absolute;
  width: 178px;
  height: 288px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 30%;
  background-size: cover;
  z-index: 1;
  pointer-events: none; display: none;
}

.hamburger_hrd {
  display: table;
  width: 100%;
  padding: 12px 12px 11px;
  border-bottom: 1px solid #f0ddd1;
}
.hamburger_logo {
  float: left;
}
.hamburger_logo a {
  display: block;
}
.hamburger_logo a div{ display: block !important;}
.hamburger_close {
  float: right;
  padding-top:2px;
}
.hamburger_close ul li {
  float: left;
  margin-left: 20px; position: relative;
}
.hamburger_close li.inr_desk select { padding: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #c4601d;
  letter-spacing: 0.25px; max-width: 56px;
  display: block;appearance: none;    padding-right: 12px;padding-left: 5px;
}
.hamburger_close li.inr_desk:before {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #c4601d;
  content: "";
  position: absolute;
  right: 0;
  top: 6px;    pointer-events: none;    border-radius: 2px;
}
.hamburger_close li.inr_desk select option{ font-size: 12px;}
.hamburger_menu3 ul li img{height: 24px;}
.hamburger_close li.inr_desk ul {
  display: none;
  position: absolute;
  float: none;
  background: #f5f5f5;
}
.hamburger_close li.inr_desk ul li a:before {
  display: none;
}
.hamburger_close li.inr_desk:hover ul {
  display: block;
}
.hamburger_close li.inr_desk ul a {
  padding: 6px 12px;
  color: #313131;
}
.hamburger_menu1 {
  width: 100%;
  display: table;
}
.hamburger_menu1 ul li a {
  text-transform: capitalize;
  position: relative;
  font-family: Cormorant Garamond;
  font-size: 22px;
  color: #313131;
  font-weight: 400;
  display: block;
  line-height: 1;
  border-bottom: 1px solid #f0ddd1;
  padding: 12px;
}
.hamburger_menu1 ul li a.arrow_icon:before {
  content: "";
  background-image: url("/images/arrow_icon.inline.svg");
  height: 14px;
  width: 8px;
  position: absolute;
  right: 16px;
  top: 16px;
}
.hamburger_menu1 ul li ul li a{ font-size: 18px;}
.hamburger_menu1 ul li ul li:nth-last-child a{ border-bottom: 0;}
.hamburger_menu1 ul li ul{background-color: #fffdee;}
.hamburger_menu2 {
  width: 100%;
  display: table;
}
.hamburger_menu2 li a {
  font-family: Cormorant Garamond;
  font-size: 18px;
  color: #313131;
  line-height: 1.2;
  display: block;
  padding: 10px 12px;
}
.hamburger_menu2 ul {
  padding-top: 10px;
}
.hamburger_menu3 {
  width: 100%;
  display: inline-block; padding-bottom: 20px;padding-top: 20px;
}
.hamburger_menu3 ul li {
  float: left;
  margin-right: 20px;
}
.hamburger_menu3 ul {
  padding-left: 12px;
  padding-top: 12px;
  display: table;
}
.hamburger_menu3 p {
  margin: 0;
  font-size: 13px;
  color: #c4601d;
  letter-spacing: 0.15px;
  padding-left: 12px;
  padding-top: 10px;
}

.menuIcon{
    padding-top: 6px;
}
header.header{    box-shadow: 0px 3px 6px rgb(82 53 40 / 16%);    padding:0px 0 0px 0;    padding-top:9px;min-height: 48px;}
.menuIcon{ padding-top: 6px;}
.logoMain {    padding: 2px 0 4px;}
.right-menu{    padding-top: 2px;}
.rightMenu{    padding-top: 2px;padding-left: 0;}
.hamburger_menu11{  background: #f9f4e8; padding: 10px 12px; width: 100%; position: relative;    display: flow-root;}
.hamburger_menu11 a{    text-transform: capitalize;    font-size: 14px; color: #C4601D;letter-spacing: 0.05px;font-weight: 600; float: left; }
.hamburger_sing{ float: right;}
.hamburger_sing a, .hamburger_sing button{ float: none; font-size: 12px; font-weight: 400; font-style: italic;
  color: rgb(49 49 49 / 65%);    background: none;}
  .rightMenu ul li.bagCart a span{  background-color: #C4601D; position: absolute;   top: -6px;
 width: 18px; height: 18px; display: block;  text-align: center;  right:-7px; border-radius: 50%;
 color: #fff; font-size: 10px;  z-index: 0;  line-height: 18px;}
  .hamburger_menu { display:flow-root; width: 100%; }
  .hamburger_menu li{ width:50%; float: left;}
  .hamburger_menu ul li a { font-family: Cormorant Garamond; font-size: 18px; color: #313131;
 line-height: 1.2; display: block;  padding: 10px 12px;      background: rgb(247 247 247 / 20%);
 border-left: 1px solid rgb(240 240 240 / 50%); border-bottom: 1px solid rgb(240 240 240 / 50%);}
 .hamburger_menu ul li:first-child a{ border-left: 0;}
 .subscribe_menu3 button{    background: inherit;font-family: Cormorant Garamond; font-size: 18px;color: #313131;
  line-height: 1.2; display: block; padding: 10px 12px;}
  .new{font-weight: 600; letter-spacing: 1px;background: #c4601d; color: #fff; padding: 2px 8px;
    border-radius: 3px; font-size: 8px;   line-height: 1; position: relative;top: -3px;
    font-family: 'Open Sans'; text-transform: uppercase;  white-space: nowrap;}
    .promo_add{    display: inline-block; width: 100%; text-align: center;    padding:4px 0;  
        color:#fff ;    background: #C4601D;min-height: 23px;display: flow-root;}
    .promo_add p{ margin: 0; font-size: 12px;letter-spacing: 0.5px;     color: #fff; line-height: 1.3;}
    .promo_add p a{ margin: 0; font-size: 12px;letter-spacing: 0.5px;     color: #fff;}
    header.sticky_header{ position: fixed;animation-play-state: running; animation-timing-function: ease;
 background-color: #fffdee;transform: translate(0,0); animation-delay: 0s; animation-direction: normal;
 animation-duration: .5s;animation-iteration-count: 1; animation-fill-mode: both; animation-name:fadeInDown;}
    .modal_international{ width:700px; position:fixed;background: #fff; border-radius: 6px;
      transform: translate(-50%, -50%);  left: 50%;top: 50%;z-index: 999;    padding: 25px;
      box-shadow: 0px 0px 6px rgb(49 49 49 / 16%);}  
      .close_btn{ position: absolute;    background: #f9f4e8;     right: -19px;  top: -19px; width: 38px;
       height: 38px; border-radius: 50%;text-align: center;display: flex;justify-content: center;
       align-items: center;}     
       .modal_body{ width: 100%; display: block;}   
      .modal_international a{    background-color: #fff; width: auto;  display: inline-block !important;  
          color: #C4601D !important; cursor: pointer; padding:12px 42px;border: 1px solid #C4601D; min-width: 264px;    letter-spacing: 1.25px;    border-radius: 5px;
      font-size: 12px !important; text-align: center; text-transform: uppercase;}
.menutoggle{ float: left;}
.menusearch{ float: left; line-height: 1;    margin-left: 14px;}
.menusearch a{ display: block;    position: relative;  top: -4px;}
/* .bagCart_ul{ position: fixed;inset: 0px;background: rgba(0, 0, 0, 0.4); font-size: 14px;
  box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px;z-index:999;}
  .bagCart_ul ul{    position: absolute;top: 72px; right: 12px;display: inline-block;
list-style: none; padding: 0px; min-width: 160px;background: rgb(255, 255, 255);border-radius: 5px;}
.bagCart_ul ul li{    display: block; border-bottom: 1px solid rgb(245, 245, 245);    float: none;margin: 0;}
.bagCart_ul ul li a{    display: block;padding:8px 20px; text-decoration: none;
white-space: nowrap;color: rgb(63, 65, 77);} */
.dropdown-ul-wrap{top:48px !important;right:0 !important;transform:unset !important;left:unset !important;z-index: 9;}
.dropdown-ul { float: none !important;}
.dropdown-ul li{ float: none !important;}
 .dropdown-ul li a{ display: block; padding:9px 15px; 
    border-bottom: 1px solid #f2f2f2;letter-spacing: 1.25px;  font-size: 13px;
color: #C4601D !important; }
.dropdown-ul li a img{vertical-align: text-bottom;}
.dropdown-ul-wrap ul { background-color: #fff; box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px;position: relative;
  border-radius: 5px;width: 180px;    z-index: 9;}
  .dropdown-ul-wrap:before { content: "";background: rgba(0, 0, 0, 0.4);box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px;
  position: fixed;width: 100%;height: 100vh;top: 0;bottom: 0;left: 0; }
  .dropdown-ul-wrap ul li {margin-left: 0;}
  .dropdown-ul-wrap ul li:last-child a {border-bottom: 0;}
  .dropdown-ul-wrap {right: 12px !important;will-change: initial !important;}
  .house_icon{ float: right;    position: relative; top: 1px;}

      @media (max-width: 767px){
        .modal_international{ width:100%; max-width: 88%;}
        header.sticky_header + .header_margin_top{ padding-top: 48px;}

      }
    
      @-webkit-keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            -moz-transform: translatey(-10px);
            -o-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            -moz-transform: translatey(0);
            -o-transform: translatey(0);
            transform: translatey(0);
        }
    }
    @-moz-keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            -moz-transform: translatey(-10px);
            -o-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            -moz-transform: translatey(0);
            -o-transform: translatey(0);
            transform: translatey(0);
        }
    }
    @keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            -moz-transform: translatey(-10px);
            -o-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            -moz-transform: translatey(0);
            -o-transform: translatey(0);
            transform: translatey(0);
        }
    }