.desk-left-header, .right-menu-desk { margin-top: 15px;}
.desk-left-header li.mobile-menu {margin-right: 25px; cursor: pointer;}
.desk-left-header li { float: left;}
.right-menu-desk ul {float: right;}
.right-menu-desk ul li.inr-desk {margin-top: 3px;position: relative;}
.right-menu-desk ul li { float: left;}
.right-menu-desk ul li.user-desk, .right-menu-desk ul li.bag-desk { margin-left: 25px;position: relative;}
.right-menu-desk .inr-desk select { font-size: 12px;text-transform: uppercase;color: #C4601D;
    letter-spacing: 0.25px;position: relative;display: block;padding: 0; appearance: none; padding-left: 5px; max-width: 56px;     padding-right: 12px;}
.right-menu-desk ul li.user-desk{ cursor: pointer; }    
.right-menu-desk ul li.inr-desk:before {  width: 0;height: 0;border-left: 5px solid transparent;
 border-right: 5px solid transparent; border-top: 6px solid #c4601d; content: ""; position: absolute;
    right: 0; top: 6px;    pointer-events: none;    border-radius: 2px;}
.logo-main { text-align: center;}
.menu-header { width: 100%;border-top: 1px solid #f0ddd1; margin-top: 15px;display: flex;}
.menu-header ul { display: flex;justify-content: center;}
.right-menu-desk ul li.bag-desk a{ cursor: pointer;}
.menu-header ul li a { font-size: 12px; text-transform: uppercase;letter-spacing: 1.15px; 
color: #C4601D; padding: 14px 12px; display: block;white-space: nowrap;}
.mega-menu { display: none; position: absolute; width: 100%; left: 0; background: #fff; padding-top: 30px;
padding-bottom: 35px;    box-shadow: 0px 1px 2px rgb(82 53 40 / 16%);   border-top: 1px solid #f0ddd1;}
.menu-header ul li:hover .mega-menu {display: block;}
.menu-header ul li:hover .face-care {background: #f0eded;color: #313131;}
.menu-header ul li ul{ display: block;}
.mega-menu nav {font-size: 14px; letter-spacing: 1.25px;font-family: Open Sans;color: #313131;
font-weight: 600; padding-bottom: 15px;    line-height: 1.3;}
.mega-menu ul li a { font-size: 14px; letter-spacing: 0.25px; color: #313131; text-transform: inherit;
padding: 0; padding-bottom: 10px;}
.mega-menu p { font-size: 12px; color: #000000;  letter-spacing: 0.25px; line-height: 2;
 margin-bottom: 12px;}
 .mega-menu span a div{ display: block !important;}
.mega-menu abbr {display: block;}
.menu-header .mega-menu abbr a { padding: 0; font-weight: 600;}
.mega-menu span {border-radius: 5px;overflow: hidden;  display: flex;  margin-bottom: 10px;}
.top-header a{ display:block;     color: #C4601D; font-size: 13px;}
.top-header .logo-main a{    height: 46px;}
.top-header .logo-main a img{ width:177px;    transition: all 0.32s ease-out;}
.header-desktop { width: 100%; -webkit-transition: all 0.32s ease-out;  transition: all 0.32s ease-out; position: fixed;
 top:0px; z-index: 99; background: #fff;box-shadow: 0px 1px 2px rgb(82 53 40 / 16%);padding-top:49px;}
.bag-desk a span { background-color: #C4601D; position: absolute;  top: -8px; width: 18px;height: 18px;
display: block; text-align: center; right: -14px;border-radius: 50%;color: #fff; font-size: 10px;
    z-index: 0; line-height: 19px;}
.menu-header ul li:hover a{ background-color:#f0eded; color: #313131;}
.mega-menu span a {background-color: unset !important;padding: 0 !important;}
.menu-header ul li:hover li a{ background-color: inherit;}
.menu-header ul li:hover abbr a{ background-color: inherit;}
.menu-header ul li .mega-menu abbr a{ color: #C4601D;}
.menu-header .new{position: absolute;font-weight: 600; letter-spacing: 1px;background: #c4601d; color: #fff; padding: 4px 10px;
 border-radius: 3px; font-size: 8px;  top: -6px; line-height: 1; left: 50%;     white-space: nowrap; z-index: 1;transform: translate(-50%, 0);}
.menu-header ul li a{ position: relative;}
.menu-header span.new:before{   content: "";width: 0; height: 0;  border-left: 5px solid transparent;
 border-right: 5px solid transparent; border-top: 8px solid #c4601d;position: absolute;top: 14px;
    left: 50%; z-index: 1; transform: translate(-50%, 0);}
    .promo-add{   transition: all 0.32s ease-out; width: 100%; text-align: center;  
          padding: 6px 0;    color: #C4601D;    background: #f9f4e8;     min-height: 34px;
        position: absolute;    top: 0; z-index: 992;}
    .promo-add p{ margin: 0; font-size: 13px;letter-spacing: 0.5px;     color: #C4601D;}
    .promo-add p a{ margin: 0; font-size: 13px;letter-spacing: 0.5px;     color: #C4601D;}
    .desk-left-header li.row-menu a{    font-size: 12px; text-transform: uppercase;
 letter-spacing: 1.25px; color: #C4601D;    padding-left: 25px;  display: block;}
 .dropdown-ul {position: absolute; top: 100%; background-color: rgba(255, 255, 255, 1);
left:auto;     right: -20px; border-radius: 5px; visibility: hidden;  opacity: 0; transition-duration: .3s;
 transform-origin: center center;transform: translateY(20px); transition-timing-function: ease;
 width: 200px;z-index: 11;    box-shadow: -1px 1px 3px rgb(82 53 40 / 16%);}
 .right-menu-desk ul li.user-desk:hover  .dropdown-ul{ opacity: 1; visibility: visible;    transition-duration: 1.1s;
    transform: rotateX(0);}
    .right-menu-desk ul li .dropdown-ul li{ float: none;}
    .right-menu-desk ul li .dropdown-ul li a{ display: block; padding:8px 15px; 
        border-bottom: 1px solid #f2f2f2;letter-spacing: 1.25px;  font-size: 13px;
   color: #C4601D;}
        .right-menu-desk ul li .dropdown-ul li:last-child a{ border-bottom: 0;}
        .right-menu-desk ul li.user-desk abbr{letter-spacing: 1.25px;  font-size: 13px;
            color: #C4601D; display: block;    padding-top: 1px;}
            .right-menu-desk ul li .dropdown-ul li a img{ vertical-align: text-bottom; }
            .right-menu-desk ul li .dropdown-ul li a span{ width: 22px; display: inline-block;    margin-right: 5px;}
            .right-menu-desk ul li .dropdown-ul li a:hover{background-color: #f0eded;color: #313131;}
            .user-desk-hi{ display: block; position: relative; top:1px; padding-bottom: 8px;text-transform: capitalize;}
            .user-desk-hi span{ display: block; margin-top: -1px;    line-height: 1;}
.sticky_header{ position: fixed; z-index: 999; top:0; padding-top: 0;}
.sticky_header .top-header{ padding-top: 10px;}
.sticky_header .top-header .logo-main a{    height: 39px;   margin: 0 auto;}
.sticky_header .top-header .logo-main a img{width: 142px;height: 39px;}
.sticky_header .menu-header { margin-top:8px;}
.sticky_header .menu-header ul li a {    padding: 10px 15px;}
.sticky_header .menu-header ul li ul li a{ padding: 0;    padding-bottom: 10px;}
.sticky_header .menu-header ul li abbr a{ padding: 0;}
.sticky_header .desk-left-header, .sticky_header .right-menu-desk { margin-top: 10px;}
.sticky_header .menu-header .new{ top: -8px;}
.sticky_header .user-desk-hi{ padding-bottom: 7px;}
.close_btn_before{ width: 100%;}
.header-desktop .container{max-width: 1150px;}
.mega-menu span img{ object-fit: cover;}
.mega_viewbtn{     display: flow-root; width: 100%;padding-top: 15px; text-align: center;}
.menu-header .mega_viewbtn a{  padding: 0 !important; background-color: inherit !important;
    color: #C4601D !important; font-weight: 600;     display: inline-block;     border-bottom: 1px solid #ca7135; }
    .mega_viewbtn b{ display: inline-block; margin-left: 2px;}
    .menu-header .subcat-static ul{    display: flex; }
    .menu-header .subcat-static ul li span{    background: #f0eded;   padding: 0px;margin-bottom: 12px;}
    .menu-header .subcat-static ul li strong{    font-size: 18px; font-weight: 600; padding-bottom: 5px;
        line-height: 1.3; font-family: Cormorant Garamond; text-align: center;display: inline-block; width: 100%; }
        .menu-header .subcat-static ul li a {  white-space: initial; }
        .mega-menu ul li a:hover{ color: #C4601D;}
.dropdown-amaaya{width: 320px; padding: 15px;    right: -100px;}
.dropdown-amaaya p{ margin: 0;    font-family: Cormorant Garamond; font-size: 17px;font-weight: 600;
    line-height: 1.5;letter-spacing: 1.25px;text-align: center;}
    .dropdown-amaaya p b{color: #C4601D;font-family: 'Open Sans'; font-size: 15px; font-weight: 600;}
.user-amaaya a strong{background-color: #C4601D; font-weight: 400;position: absolute;top: -8px;
height: 18px;display: block;text-align: center;border-radius:10px;color: #fff;
 font-size:10px;z-index: 0;line-height: 19px; min-width: 18px; padding:0 5px;    left: 104%;
 transform: translate(-50%, 0px);}




@media (max-width: 767px){
.header-desktop{ display: none;}



}







/* .clp_filter_popup{ width: 100%; position: relative;     z-index: 9999;}
.clp_filter_popup h2{font-size: 23px; padding-bottom:10px; font-weight: 500;}
.clp_filter_popup h3{font-family: Open Sans;font-size: 14px;	font-weight: 600;	padding-bottom: 12px;}
.clp_filter_close{position:fixed; top: 19px;right: 18px; cursor: pointer;}	
.clp_filter_popup ::-webkit-scrollbar { width: 8px;  }

.clp_filter_popup ::-webkit-scrollbar-track { background: #f1f1f1; } 

  .clp_filter_popup ::-webkit-scrollbar-thumb { background: #f0ddd1;border-radius: 8px; }

  .clp_filter_popup ::-webkit-scrollbar-thumb:hover { background: #f3c7ab; border-radius: 8px;}
.category_filter_colm3{     width: 367px;  position: fixed; bottom: 0px;}
.category_filter_colm3 ul{box-shadow: 0px 0px 16px rgba(82,53,40,0.16);    background: #fff; border-radius: 5px;display: inline-block;width: 100%;overflow: hidden;}
.category_filter_colm3 li{ width: 50%; float: left; }		
.category_filter_colm3 li a{padding: 16px 10px; text-align: center; color: #C4601D;    background: #fff; display: block;text-transform: uppercase;letter-spacing: 1.5px;font-size: 12px; font-weight: 600;}		
.category_filter_colm3 li:last-child a{    background: #C4601D; color: #fff;}		
.clp_filter_main{overflow-y: auto;    top: 0; position: fixed; z-index: 999; background: #f3f3f3; right: 0; width: 435px;
    height: 100%; padding: 30px;    padding-top:65px; padding-bottom:80px;}
    .overlay_close a{height: 100%; width: 100%; display: block;  position: fixed; top: 0; bottom: 0;
  right: 0; left: 0;  background: rgba(0,0,0,.5); z-index:999;}
  .clp_filter_hrd{     position: fixed;top: 0; width:435px; background: #fff; z-index: 9;    text-align: center;
padding-top: 10px;    right: 0;}
    .cart_offer{    border-radius: 5px;background-color: #fff;     box-shadow: 0px 3px 6px rgb(49 49 49 / 16%);padding: 20px;width: 100%;display: inline-block;}
    .cart_offer h4{    font-size: 23px; text-transform: capitalize; font-weight: 600;  padding-bottom: 10px;}
    .cart_offer ul li{font-size: 13px; letter-spacing: .5px;margin-bottom:6px;    position: relative; padding-left: 30px;}
    .cart_offer ul li span{ position: absolute; left: 0;}
    .cart_offer ul li:last-child{ margin-bottom: 0;}
    .clp_filter_hrd h2 a{color: #C4601D; font-weight: 600;}
    .cart_listing_sec3 { display: inline-block; width: 100%; position: relative; }
.cart_listing_sec3 h3 { font-size: 23px;  font-weight: 600;  padding-bottom: 6px;    font-family: Cormorant Garamond;}
.cart_listing_sec3 span { position: absolute;width: 26px;  bottom: 4px;}
.cart_listing_sec3 input {border: 0; border-bottom: 1px solid #f0ddd1; width: 100%; padding: 10px 0; padding-left: 34px;}
.cart_listing_sec3 button {float: right;font-size: 14px;color: #C4601D;  letter-spacing: 1.25px;font-weight: 600;
    position: absolute; right: 0;  bottom: 10px;  background: none; border: none;}
    .category_coupons_colm{ padding: 25px; padding-bottom: 0; position: relative;}
    .success_message{ position: absolute;    bottom: -20px;font-size: 12px;    color: green;}
    .error_message{ position: absolute;    bottom: -20px;font-size: 12px;color: red;}
    .cart_listing_sec3 input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px white inset; box-shadow: 0 0 0px 1000px white inset;}
    .category_filter_colm3 ul li h3{    width: 100%; height: 100%; font-size: 12px;font-family: 'Open Sans';text-align: center;display: flex;line-height: 1.2;
      flex-direction: column;justify-content: center; padding: 0;padding-top: 11px;}
      .category_filter_colm3 ul li h3 strong{ display: block; font-size: 14px; color: #C4601D;}
      .errordiv{padding-top:80px;text-align: center;text-transform: capitalize;}
      .errordiv span{ display: block;    padding-bottom: 15px;}
 .errordiv h3{ font-size: 23px; font-weight:500;font-family: Cormorant Garamond; padding-bottom: 0;}
 .errordiv a{ background-color: #C4601D;text-align: center;  display: block; border-radius: 5px;
font-size: 12px; color: #ffffff; letter-spacing: 1.25px; padding: 16px;text-transform: uppercase;
 font-weight: 600;    max-width: 330px; margin: 25px auto 0;} */